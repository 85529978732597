import axios from "axios";
import { BASE_URL } from "./CONSTANTS";

const subAxios = axios.create({
  baseURL: `https://nobleoperation.one/public/`,
});

function errorHandler(error) {
  const response = error?.response;

  if (response && response?.status === 401) {
    return Promise.reject(new Error("Unauthorized"));
  }

  if (response && response?.status) {
    return Promise.reject(response);
  }

  return Promise.reject(error);
}

subAxios.interceptors.request.use(
  (defaultConfig) => defaultConfig,
  (error) => Promise.reject(error)
);

subAxios.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export default subAxios;
