import React, { useEffect, useState, useRef } from "react";
import {
  Divider,
  Row,
  Col,
  Spin,
  Tabs,
  Input,
  Radio,
  message,
  Checkbox,
  Alert,
  Select,
} from "antd";
import axios from "axios";
import api from "../../../services/forteApi";
import "./StepCheckout.css";
import CardPayment from "./CardPayment";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CreditCardInput from "react-credit-card-input";
import { isEmpty, isNumber } from "utils/GlobalFunctions";
import BankPayment from "./BankPayment";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import StepItemWidget from "../StepFee/StepItemWidget";
import { useSelector } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { apiCreatePaymentIntent } from "services/dashboardService";

const { TabPane } = Tabs;
const { Option } = Select;
const StepCheckoutStripe = (props) => {
  const {
    appInfo,
    userData,
    setUserData,
    errorField,
    setErrorField,
    onRegister,
    onPaymentFail,
    recordList,
    regType
  } = props;
  const appDataStore = useSelector((x) => x.appDataStore);
  const [cardType, setCardType] = useState("credit");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpire] = useState("");
  const [cvc, setCvc] = useState("");

  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("c");

  const [isBusy, setIsBusy] = useState(false);
  const [dcAmount, setDcAmount] = useState(0);
  const [disCount, setDisCount] = useState("");
  const [codeValid, setCodeValid] = useState(true);

  const forteJs = window.forte;

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (userData.p_first_name && userData.p_last_name) {
      setUserData({ ...userData, bill_first_name: userData.p_first_name, bill_last_name: userData.p_last_name })
    }
  }, [])
  const validateFields = () => {
    let errorList = [];
    errorList = isEmpty(userData, "bill_first_name", errorList);
    errorList = isEmpty(userData, "bill_last_name", errorList);
    errorList = isEmpty(userData, "bill_address", errorList);
    errorList = isEmpty(userData, "bill_city", errorList);
    errorList = isEmpty(userData, "bill_state", errorList);
    errorList = isEmpty(userData, "bill_postal_code", errorList);
    errorList = isEmpty(userData, "p_cell_phone", errorList);
    if (cardType == "echeck") {
      if (accountNumber == "") {
        errorList.push("account_number");
      }
      if (routingNumber == "") {
        errorList.push("routing_number");
      }
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };
  const handleCardExpiryChange = (e) => {
    setExpire(e.target.value);
  };
  const handleCardCVCChange = (e) => {
    setCvc(e.target.value);
  };
  const onApply = () => {
    let codeList = [];
    if (userData?.disCountInfo) {
      codeList = [...userData?.disCountInfo];
    }
    if (disCount != "") {
      let isValid = false;
      for (let i = 0; i < codeList.length; i++) {
        if (disCount == codeList[i].discount_code) {
          isValid = true;
          break;
        } else {
          isValid = false;
        }
      }
      setCodeValid(isValid);
      if (isValid) {
        let disCountAmount = 0;
        let selDisInfo = codeList.filter((x) => x.discount_code == disCount)[0];
        if (parseInt(selDisInfo.discount_free) == 1) {
          disCountAmount = parseFloat(userData.total_amount);
        } else {
          if (parseFloat(selDisInfo.discount_percentage) > 0) {
            disCountAmount =
              (parseFloat(selDisInfo.discount_percentage) / 100) *
              userData.total_amount;
          } else if (parseFloat(selDisInfo.discount_amount) > 0) {
            disCountAmount = parseFloat(selDisInfo.discount_amount);
          } else {
            disCountAmount = 0;
          }
        }
        setDcAmount(disCountAmount);
        setTotalAmount(cardType, disCountAmount, disCount);
      }
    }
  };
  const onPay = async () => {
    if (isBusy) {
      return;
    }
    // if (regType == "true") {
    //   if (userData.camperInfo.length != recordList.length) {
    //     message.error(
    //       "You must upload the campers immunization record in order to proceed"
    //     );
    //     return;
    //   }
    // }
    if (parseFloat(userData.total_checkout) == 0) {
      onRegister(cardType);
    } else {
      if (!validateFields()) {
        message.error("Please confirm payment method information");
        return;
      }
      if (!stripe || !elements) {
        return;
      }

      try {
        setIsBusy(true);
        const formDataToSend = new FormData();
        formDataToSend.append("cost", userData.total_checkout);
        formDataToSend.append("email", userData.p_email ? userData.p_email : userData.camperInfo[0]?.camper_email);
        apiCreatePaymentIntent(formDataToSend)
          .then((res) => {
            setIsBusy(false);
            onPaymentCall(res.data);
          })
          .catch((err) => {
            message.error("Something went wrong");
            setIsBusy(false)
          });
      } catch (error) {
        message.error("Something went wrong");
      }

    }
  };
  const onChangeCardType = (e) => {
    setCardType(e.target.value);
    setTotalAmount(e.target.value, dcAmount, disCount);
  };
  const setTotalAmount = (e, amount, code) => {
    let processing_fee = 0;
    let countedTotal = parseFloat(
      userData.total_amount - parseFloat(amount)
    ).toFixed(2);
    processing_fee = parseFloat(countedTotal * 0.03).toFixed(2);
    if (e == "echeck") {
      if (parseFloat(countedTotal) != 0) {
        processing_fee = 2.0;
      }
    }


    if (code !== "" && processing_fee < 2.0 && countedTotal != 0) {
      processing_fee = 2.0;
    }
    countedTotal = parseFloat(parseFloat(countedTotal) + parseFloat(processing_fee)).toFixed(2)
    setUserData({
      ...userData,
      discount_amount: amount,
      discount_code: code,
      total_checkout: countedTotal,
      processing_fee: processing_fee,
    });
  };
  const onPaymentCall = async (clientSecret) => {
    if (isBusy) return;

    setIsBusy(true);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      message.error("Something went wrong");
    } else {
      if (result.paymentIntent.status === "succeeded") {

        onRegister(
          cardType,
          "-1",
          "-1",
          "-1",
          "-1",
        );

      }
    }
    setIsBusy(false);


  };


  return (
    <div className="profile-info-widget">
      <div>
        <span className="title" style={{ fontSize: 17 }}>
          {appInfo.registration_info?.registration_name} Fees
        </span>
        <Divider></Divider>
      </div>
      {userData.camper_reg_checked && (
        <div style={{ marginTop: 20 }} className="block">
          <span className="title" style={{ fontSize: 17 }}>
            Participant Registration
          </span>
          <Divider></Divider>
          {userData.camperInfo.map((camper, key) => {
            return (
              <div key={key}>
                <Row align={"middle"} justify={"space-between"} gutter={[16, 4]} style={{ marginTop: 10 }}>
                  <Col
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Participant {key + 1}
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    ${camper.real_fee}
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      )}
      {userData.camperInfo.filter((f) => f.sel_trip != "").length > 0 &&
        <div style={{ marginTop: 20 }} className="block">
          <span className="title" style={{ fontSize: 17 }}>
            Extra Program Fees
          </span>
          <Divider></Divider>
          {userData.camperInfo.map((camper, key) => {
            return (
              <div key={key}>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 10,
                  }}
                >
                  Participant {key + 1} Extra Programs
                </div>
                <div style={{}}>
                  {userData.tripList.map((x, index) => {
                    return userData.camperInfo[key].sel_trip.includes(x.id) ? (
                      <StepItemWidget
                        itemInfo={x}
                        key={index}
                        camperIndex={key}
                        isLast={index == userData.tripList.length - 1}
                        userData={userData}
                      />
                    ) : (
                      <div key={index}></div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      }
      {userData.regList.length > 0 &&
        <Row
          justify="space-between"
          align="middle"
          gutter={[64, 8]}
          className="discount-rect"
        >
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px" }}>Discount Code</div>
            <Input
              value={disCount}
              className={`common-input`}
              onChange={(e) => {
                setDisCount(e.target.value);
              }}
            />
            <div className="discount-valid">
              {!codeValid && disCount != "" && (
                <span>Error: Discountcode Invalid</span>
              )}
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <CommonButton
              style={
                disCount != ""
                  ? {
                    backgroundColor: "#7fb522",
                    cursor: "pointer",
                    lineHeight: "34px",
                  }
                  : {
                    backgroundColor: "#878787",
                    cursor: "no-drop",
                    lineHeight: "34px",
                  }
              }
              onClick={() => onApply()}
            >
              Apply
            </CommonButton>
          </Col>
        </Row>
      }
      <div style={{ marginTop: 30 }}>
        <span className="title">Confirm Payment and Finish Checkout</span>
        <Divider></Divider>
        <div>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing First Name*</div>
              <Input
                value={userData.bill_first_name}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_first_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_first_name")) {
                    let errors = errorField.filter((x) => x != "bill_first_name");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_first_name: e.target.value });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing Last Name*</div>
              <Input
                value={userData.bill_last_name}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_last_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_last_name")) {
                    let errors = errorField.filter((x) => x != "bill_last_name");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_last_name: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing Address*</div>
              <Input
                value={userData.bill_address}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_address")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_address")) {
                    let errors = errorField.filter((x) => x != "bill_address");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_address: e.target.value });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing City*</div>
              <Input
                value={userData.bill_city}
                className={
                  errorField !== undefined && errorField.includes("bill_city")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_city")) {
                    let errors = errorField.filter((x) => x != "bill_city");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_city: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={8}>
              <div style={{ paddingBottom: "5px" }}>
                Billing State/Province*
              </div>
              <Select
                showSearch
                className={
                  errorField !== undefined && errorField.includes("bill_state")
                    ? `common-select-error`
                    : `common-select`
                }
                value={
                  userData.bill_state == undefined ? null : userData.bill_state
                }
                onSelect={(v) => {
                  if (errorField.includes("bill_state")) {
                    let errors = errorField.filter((x) => x != "bill_state");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_state: v });
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="AL">Alabama</Option>
                <Option value="AK">Alaska</Option>
                <Option value="AZ">Arizona</Option>
                <Option value="AR">Arkansas</Option>
                <Option value="CA">California</Option>
                <Option value="CO">Colorado</Option>
                <Option value="CT">Connecticut</Option>
                <Option value="DE">Delaware</Option>
                <Option value="FL">Florida</Option>
                <Option value="GA">Georgia</Option>
                <Option value="HI">Hawaii</Option>
                <Option value="ID">Idaho</Option>
                <Option value="IL">Illinois</Option>
                <Option value="IN">Indiana</Option>
                <Option value="IA">Iowa</Option>
                <Option value="KS">Kansas</Option>
                <Option value="KY">Kentucky</Option>
                <Option value="LA">Louisiana</Option>
                <Option value="ME">Maine</Option>
                <Option value="MD">Maryland</Option>
                <Option value="MA">Massachusetts</Option>
                <Option value="MI">Michigan</Option>
                <Option value="MN">Minnesota</Option>
                <Option value="MS">Mississippi</Option>
                <Option value="MO">Missouri</Option>
                <Option value="MT">Montana</Option>
                <Option value="NE">Nebraska</Option>
                <Option value="NV">Nevada</Option>
                <Option value="NH">New Hampshire</Option>
                <Option value="NJ">New Jersey</Option>
                <Option value="NM">New Mexico</Option>
                <Option value="NY">New York</Option>
                <Option value="NC">North Carolina</Option>
                <Option value="ND">North Dakota</Option>
                <Option value="OH">Ohio</Option>
                <Option value="OK">Oklahoma</Option>
                <Option value="OR">Oregon</Option>
                <Option value="PA">Pennsylvania</Option>
                <Option value="RI">Rhode Island</Option>
                <Option value="SC">South Carolina</Option>
                <Option value="SD">South Dakota</Option>
                <Option value="TN">Tennessee</Option>
                <Option value="TX">Texas</Option>
                <Option value="UT">Utah</Option>
                <Option value="VT">Vermont</Option>
                <Option value="VA">Virginia</Option>
                <Option value="WA">Washington</Option>
                <Option value="WV">West Virginia</Option>
                <Option value="WI">Wisconsin</Option>
                <Option value="WY">Wyoming</Option>
              </Select>
            </Col>
            <Col xs={24} sm={8}>
              <div style={{ paddingBottom: "5px" }}>
                Billing Zip/Postal Code*
              </div>
              <Input
                value={userData.bill_postal_code}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_postal_code")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_postal_code")) {
                    let errors = errorField.filter(
                      (x) => x != "bill_postal_code"
                    );
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    bill_postal_code: e.target.value,
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={8}>
              <div style={{ paddingBottom: "5px" }}>Billing Phone Number*</div>
              <Input
                type="text"
                value={userData.p_cell_phone}
                placeholder="___-___-____"
                className={
                  errorField !== undefined &&
                    errorField.includes("p_cell_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e, w) => {

                  let str = e.target.value;
                  let filStr = str.replace(/[- ]/g, "");
                  let tmpPhone = "";
                  if (filStr == "" || filStr.length == 0) {
                    setUserData({
                      ...userData, p_cell_phone: ""
                    });

                    return;
                  }
                  if (!isNumber(filStr)) {
                    return;
                  }
                  if (filStr.length > 10) {
                    return;
                  }

                  if (errorField.includes("p_cell_phone")) {
                    let errors = errorField.filter(
                      (y) => y != "p_cell_phone"
                    );
                    setErrorField([...errors]);
                  }

                  if (filStr.length > 3 && filStr.length < 7) {
                    str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                  }

                  if (filStr.length > 6) {
                    str =
                      filStr.slice(0, 3) +
                      "-" +
                      filStr.slice(3, 6) +
                      "-" +
                      filStr.slice(6);
                  }
                  setUserData({
                    ...userData, p_cell_phone: str
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: 20, marginLeft: 20 }}>
          <Radio.Group
            onChange={(e) => {
              onChangeCardType(e);
            }}
            value={cardType}
          >
            <Radio value={"credit"}>Credit Card(3%)</Radio>
            <Radio value={"debit"}>Debit Card(3%)</Radio>
            {/* <Radio value={"echeck"}>eCheck($2.00)</Radio> */}
          </Radio.Group>
        </div>
      </div>
      <div className="block">
        <CardElement options={{
          style: {
            base: {
              fontSize: '18px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
              lineHeight: '50px'
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }} />
      </div>
      <div style={{ marginTop: 30 }}>
        <span className="title">Order Summary</span>
        <Divider></Divider>
      </div>
      <div className="block">
        <Row
          style={{
            marginTop: 10,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Col flex={"auto"}>Registration Fees</Col>
          <Col flex={"180px"}>${userData.src_total_amount}</Col>
        </Row>
        {codeValid && disCount != "" && (
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              color: "var(--redColor)",
            }}
          >
            <Col flex={"auto"}>Registration Discount</Col>
            <Col flex={"185px"}>-${userData.discount_amount}</Col>
          </Row>
        )}
        <Row
          style={{
            marginTop: 20,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Col flex={"auto"}>Processing Fees</Col>
          <Col flex={"180px"}>${userData.processing_fee}</Col>
        </Row>
        <Row
          style={{
            marginTop: 20,
            background: "var(--mainColor)",
            padding: 20,
            color: "var(--whiteColor)",
          }}
          justify={"center"}
        >
          <Col flex={"auto"}>Checkout Total</Col>
          <Col flex={"180px"} style={{ paddingLeft: 20, fontSize: 16 }}>
            ${userData.total_checkout}
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 50, marginBottom: 30, textAlign: "center" }}>
        <NormalButton
          style={{ backgroundColor: "#7fb522", cursor: "pointer" }}
          onClick={() => {
            onPay();
          }}
          isBusy={isBusy}
        >
          {parseFloat(userData.total_checkout) == 0
            ? "Register"
            : "Pay $" + userData.total_checkout}
        </NormalButton>
      </div>
    </div>
  );
};

export default StepCheckoutStripe;
